import { SVGProps, Ref, forwardRef } from 'react'

const SvgShieldCheck = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 .749a1.5 1.5 0 0 0-1.5 1.5v9c0 4.813 7.234 10.169 9.693 11.76a1.477 1.477 0 0 0 1.614 0c2.459-1.591 9.693-6.947 9.693-11.76v-9a1.5 1.5 0 0 0-1.5-1.5H3Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.75 11.053 2.45 3.481a1.05 1.05 0 0 0 1.707.051l7.843-9.927"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgShieldCheck)
export default ForwardRef
